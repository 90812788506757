export default {
  createdAt: 1680735689,
  updatedAt: 1697233877,
  slug: 'treadmill-pace-calculator',
  heading: 'Treadmill Pace Calculator',
  title: 'Easy Treadmill Pace Calculator: MPH/KM, Incline, & More',
  description: 'Calculate an average pace for any treadmill speed. Discover time or distance to complete a run at your pace. Includes: MPH, Kilometers, & incline adjustment.',
  directory: 'calculators',
  hero: {
    heading: 'Treadmill Pace Calculator',
    description: 'Determine the optimal pace, speed, time and distance for your treadmill workout. Then share your results.',
    sourceSpreadsheetUrl: 'https://docs.google.com/spreadsheets/d/10Ex8vpa5j0Os50XnYAMs-qgBkLJ2UB_MmsHeklZVuzc/edit?usp=sharing',
    featuredImgSrcsets: [{
      src: '/img/pages/treadmill-pace-calculator/hero-small.webp 1x, /img/pages/treadmill-pace-calculator/hero-small@2x.webp 2x',
      size: '(max-width: 744px)',
      aspectRatio: '784 / 380'
    }, {
      src: '/img/pages/treadmill-pace-calculator/hero-medium.webp 1x, /img/pages/treadmill-pace-calculator/hero-medium@2x.webp 2x',
      size: '(max-width: 1023px)',
      aspectRatio: '1200 / 642'
    }, {
      src: '/img/pages/treadmill-pace-calculator/hero-large.webp 1x, /img/pages/treadmill-pace-calculator/hero-large@2x.webp 2x',
      size: '(min-width: 1024px)',
      aspectRatio: '1604 / 674'
    }],
    featuredImgAlt: 'Speedometer next to calculations of running pace, incline, and MPH/Kilometers',
    features: [{
      text: 'Free'
    }, {
      text: 'No Signup'
    }, {
      text: 'Save & Share',
      tooltip: 'Your answers are auto-saved privately and securely to your local device - nowhere else. Click the link button to get a sharable url you can send.'
    }]
  },
  images: {
    resourceUrl: '/img/pages/treadmill-pace-calculator/featured-image.webp',
    resourceUrlAlt: 'Pie chart showing costs of major wedding budget items',
    twitterPost: '/img/pages/treadmill-pace-calculator/twitter-post.webp',
    openGraph: '/img/pages/treadmill-pace-calculator/og-image.webp'
  },
  reviews: [{
    quote: "My favorite tool for planning clients' treadmill sessions. Results are so clear & easy to share. Plus my clients get motivated to see how close they are to their goals.",
    authorName: 'Mauricio Castro',
    avatarUrl: '/img/authors/mauricio-castro.jpg',
    companyName: 'NSCA Certified Personal Trainer',
    companyUrl: 'https://www.linkedin.com/in/mauricio-castro-2598b1171/',
    score: 5
  }, {
    quote: 'My go-to tool for all my cardio workouts. Tells me exactly the metrics I need to hit to reach my fitness goals and saves my results automatically.',
    authorName: 'Michael Franco',
    authorTitle: 'Talent Acquisition Management Consultant',
    avatarUrl: '/img/authors/michael-franco.jpg',
    companyUrl: 'https://www.linkedin.com/in/michaelrfranco/',
    score: 5
  }, {
    quote: "The perfect treadmill workout assistant. I've been using it to plan all my indoor sessions building up to race day.",
    authorName: 'Cory Blumenfeld',
    authorTitle: '3x Startup Founder',
    avatarUrl: '/img/authors/cory-blumenfeld.jpg',
    companyUrl: 'https://www.linkedin.com/in/coryblumenfeld/',
    score: 5
  }],
  widgets: {
    primary: 'SIsuW3ntgNfYWLkwWrzW',
    primaryStaging: '1TVBkqIH3AgVG0h2Axe9'
  },
  articleSections: [{
    name: 'How to Calculate Treadmill Speeds',
    id: 'how-to-calculate-treadmill-speeds'
  }, {
    name: 'Using the Treadmill Time Calculator',
    id: 'using-the-treadmill-time-calculator'
  }, {
    name: 'Using the Treadmill Distance Calculator',
    id: 'using-the-treadmill-distance-calculator'
  }, {
    name: 'Treadmill Incline Conversions',
    id: 'treadmill-incline-conversions'
  }, {
    name: 'Conclusion',
    id: 'conclusion'
  }]
};