export default {
  createdAt: 1676677782,
  updatedAt: 1683291600,
  slug: 'wedding-budget-calculator',
  heading: 'Wedding Budget Calculator',
  title: 'Wedding Budget Calculator, No Signup - Completely Free',
  description: 'Start estimating quickly with pre-made budgets, completely free with zero Signup. Customize across 18 categories. Save and share your results.',
  directory: 'calculators',
  hero: {
    heading: 'Wedding Budget Calculator',
    description: 'Estimate the cost of your wedding to see if your are on budget, or download the',
    sourceSpreadsheetUrl: 'https://docs.google.com/spreadsheets/d/1WotdEXFVheru1Np39tir5i_j2iHYLgJoI1tMDLfG-Pg/edit?usp=sharing',
    ctaText: 'Premade Budgets',
    featuredImgSrcsets: [{
      src: '/img/pages/wedding-budget-calculator/hero-small.webp 1x, /img/pages/wedding-budget-calculator/hero-small@2x.webp 2x',
      size: '(max-width: 744px)',
      aspectRatio: '784 / 380'
    }, {
      src: '/img/pages/wedding-budget-calculator/hero-medium.webp 1x, /img/pages/wedding-budget-calculator/hero-medium@2x.webp 2x',
      size: '(max-width: 1023px)',
      aspectRatio: '1200 / 642'
    }, {
      src: '/img/pages/wedding-budget-calculator/hero-large.webp 1x, /img/pages/wedding-budget-calculator/hero-large@2x.webp 2x',
      size: '(min-width: 1024px)',
      aspectRatio: '1604 / 674'
    }],
    featuredImgAlt: 'Calculations of wedding budget category amounts surrounding a pie chart summary',
    features: [{
      text: 'Free'
    }, {
      text: 'No Signup'
    }, {
      text: 'Save & Share',
      tooltip: 'Your answers are auto-saved privately and securely to your local device - nowhere else. Click the link button to get a sharable url you can send to your wedding planner.'
    }]
  },
  images: {
    resourceUrl: '/img/pages/wedding-budget-calculator/featured-image.webp',
    resourceUrlAlt: 'Pie chart showing costs of major wedding budget items',
    twitterPost: '/img/pages/wedding-budget-calculator/twitter-post.webp',
    openGraph: '/img/pages/wedding-budget-calculator/og-image.webp'
  },
  icon: '/icons/calculator-budget.svg',
  reviews: [// {
    //   quote:
    //     'My favorite wedding planning tool. Effortless to get started and easy to keep updated.',
    //   authorName: 'Jessica Murphy',
    //   authorTitle: 'Wedding Coordinator',
    //   avatarUrl: '/img/authors/jessica-murphy.jpg',
    //   companyName: 'Unveiled Weddings',
    //   score: 5,
    // },
    // {
    //   quote:
    //     'The total simplicity of this tool makes it a joy to share with my clients.',
    //   authorName: 'Meghan Barrett',
    //   authorTitle: 'Wedding Planner',
    //   avatarUrl: '/img/authors/meghan-barrett.jpg',
    //   companyName: 'Timeless Events',
    //   score: 5,
    // },
  ],
  widgets: {
    primary: 'kpRgJDq6u9fysfjBrWGF',
    primaryStaging: 'nH4YKiTW96QKIegPsoIv',
    doughnut: 'cR7QIyVoQh9CWNrQr4lQ',
    doughnutStaging: 'Q7qYr0nOVDQUZCRnBeta',
    list: 'wabIejY7eqdwUD4oCjeC',
    listStaging: 'WBa3jvCZTXGIt0240mMF'
  },
  articleSections: [{
    name: 'How Much Will Your Wedding Cost?',
    id: 'how-much-will-your-weddings-cost'
  }, {
    name: 'Greatest Wedding Expenses - And Affordable Alternatives!',
    id: 'greatest-weddings-expenses-and-affordable-alternatives'
  }, {
    name: 'Wedding Budget Examples With a Wedding Calculator',
    id: 'wedding-budget-examples-with-a-weddings-calculator'
  }, {
    name: 'Your Wedding, Your Budget',
    id: 'your-weddings-your-budget'
  }]
};