import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import firebase from 'firebase/app';
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import collections from '@/config/collections';
const COLLECTION_NAME = collections.widgets;
export default {
  // Create database document reference
  createDocRef(db, id) {
    return db.collection(COLLECTION_NAME).doc(id);
  },

  // Query for a list of records
  query(db, srcQuery) {
    let orderBy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    if (Object.keys(srcQuery).length === 0) {
      throw Error('query: requires a query configuration with at least one query');
    }

    let query = db.collection(COLLECTION_NAME); // Append each query as where clause

    Object.keys(srcQuery).forEach(attr => {
      const queryArgs = srcQuery[attr];
      query = query.where(attr === 'id' ? firebase.firestore.FieldPath.documentId() : attr, queryArgs[0], queryArgs[1]);
    }); // Append optional order by settings

    if (orderBy.length >= 2) {
      orderBy.forEach(args => {
        query.orderBy(...args);
      });
    }

    const {
      status,
      error,
      data: srcData = [] // eslint-disable-next-line

    } = useFirestoreCollectionData(query, {
      idField: 'id'
    }); // Cast data into target type

    const data = srcData.map(itemData => itemData); // Result

    return {
      status,
      error,
      data
    };
  },

  // Return a non-updating snapshot of
  // the current database state
  async queryStatic(db, srcQuery) {
    let orderBy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    if (Object.keys(srcQuery).length === 0) {
      throw Error('query: requires a query configuration with at least one query');
    }

    let query = db.collection(COLLECTION_NAME); // Append each query as where clause

    Object.keys(srcQuery).forEach(attr => {
      const queryArgs = srcQuery[attr];
      query = query.where(attr === 'id' ? firebase.firestore.FieldPath.documentId() : attr, queryArgs[0], queryArgs[1]);
    }); // Append optional order by settings

    if (orderBy.length >= 2) {
      orderBy.forEach(args => {
        query = query.orderBy(...args);
      });
    }

    try {
      const snapshot = await query.get();
      const data = snapshot.docs.map(doc => _objectSpread({
        id: doc.id
      }, doc.data()));
      return {
        status: 'success',
        error: null,
        data
      };
    } catch (error) {
      return {
        status: 'error',
        error,
        data: []
      };
    }
  },

  // Lookup a single record
  findRecord(db, id) {
    const docRef = db.collection(COLLECTION_NAME).doc(id); // eslint-disable-next-line

    const {
      status,
      error,
      data: srcData // eslint-disable-next-line

    } = useFirestoreDocData(docRef, {
      idField: 'id'
    }); // Cast data into target record

    const data = srcData;
    return {
      status,
      error,
      data
    };
  },

  // Query for a group by ID
  findRecords(db, ids) {
    const query = db.collection(COLLECTION_NAME).where(firebase.firestore.FieldPath.documentId(), 'in', ids);
    const {
      status,
      error,
      data: srcData = [] // eslint-disable-next-line

    } = useFirestoreCollectionData(query, {
      idField: 'id'
    }); // Cast data into target types

    const data = srcData.map(itemData => itemData); // Result

    return {
      status,
      error,
      data
    };
  }

};