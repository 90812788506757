export default {
  createdAt: 1692537887,
  updatedAt: 1696617341,
  slug: 'bubble-chart-maker',
  heading: 'Bubble Chart Maker',
  title: 'Instant Bubble Chart Maker - Free Online Tool, No Signup',
  description: 'Create attractive bubble charts in seconds. Customize then download in any size or file format. Upload Excel & Google Sheets. No signup necessary.',
  shortDescription: 'Create a bubble chart and import Excel & Google Sheets. Customize then download below. No signup necessary.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/bubble-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Bubble chart map next to a selection of Excel cells',
    twitterPost: '/img/pages/bubble-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/bubble-chart-maker/og-image.webp'
  },
  icon: '/icons/chart-bubble.svg',
  reviews: [],
  articleSections: [{
    name: 'Key Features of a Bubble Chart Maker',
    id: 'key-features-of-a-bubble-chart-maker'
  }, {
    name: 'What is a Bubble Chart?',
    id: 'what-is-a-bubble-chart'
  }, {
    name: 'What Are Bubble Charts Used For?',
    id: 'what-are-bubble-charts-used-for'
  }, {
    name: 'How Do You Make a Bubble Chart Online?',
    id: 'how-do-you-make-a-bubble-chart-online'
  }, {
    name: 'What Are Bubble Charts Best For?',
    id: 'what-are-bubble-charts-best-for'
  }, {
    name: 'Which Data Type is a Bubble Chart Suitable For?',
    id: 'which-data-type-is-a-bubble-chart-suitable-for'
  }, {
    name: 'What is the Difference Between a Bubble Chart and a Scatter Chart?',
    id: 'what-is-the-difference-between'
  }, {
    name: 'What is the Difference Between a Tree Map and a Bubble Chart?',
    id: 'what-is-the-difference-between-a-tree-map-and-a-bubble-chart'
  }, {
    name: 'What is a Bubble Map?',
    id: 'what-is-a-bubble-map'
  }, {
    name: 'What is a Packed Circle Chart?',
    id: 'what-is-a-packed-circle-chart'
  }, {
    name: 'What are Bubble Chart Advantages and Disadvantages?',
    id: 'what-are-bubble-chart-advantages'
  }, {
    name: 'What are Bubble Chart Best Practices?',
    id: 'what-are-bubble-chart-best-practices'
  }, {
    name: 'What Are Popular Alternatives to Bubble Charts?',
    id: 'what-are-popular-alternatives-to-bubble-charts'
  }, {
    name: 'What is a Four-Quadrant Chart?',
    id: 'what-is-a-four-quadrant-chart'
  }, {
    name: 'How Many Variables are Required for a Bubble Diagram Maker?',
    id: 'how-many-variables-are-required-for-a-bubble-diagram-maker'
  }, {
    name: 'Examples of Bubble Chart Data and Data Labels',
    id: 'examples-of-bubble-chart-data-and-data-labels'
  }, {
    name: 'What are the Axes of a Bubble Chart?',
    id: 'what-are-the-axes-of-a-bubble-chart'
  }, {
    name: 'How to Make a Bubble Chart in Word?',
    id: 'how-to-make-a-bubble-chart-in-word'
  }, {
    name: 'How do I Create a Bubble Chart in Google Sheets?',
    id: 'how-do-i-create-a-bubble-chart-in-google-sheets'
  }, {
    name: 'Is a Bubble Chart Available in Excel?',
    id: 'is-a-bubble-chart-available-in-excel'
  }, {
    name: 'How do You Create a Bubble Chart in PowerPoint?',
    id: 'how-do-you-create-a-bubble-chart-in-presentation'
  }, {
    name: 'How do You Create a Bubble Chart in PowerPoint?',
    id: 'how-do-you-create-a-bubble-chart-in-presentation'
  }, {
    name: 'Explore Bubble Chart Templates',
    id: 'explore-bubble-chart-templates'
  }]
};