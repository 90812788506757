export default {
  createdAt: 1681729848,
  updatedAt: 1697233832,
  slug: 'swim-pace-calculator',
  heading: 'Swim Pace Calculator',
  title: 'Easy Swim Pace Calculator & Pacing Data: Meters / Yards',
  description: 'Calculate your pace for any swim event in meters or yards. Includes both time and distance calculators. Discover your ideal pace within our 6 event datasets.',
  directory: 'calculators',
  hero: {
    heading: 'Swim Pace Calculator',
    description: 'Determine the optimal pace, distance, or time for any swim distance.',
    sourceSpreadsheetUrl: 'https://docs.google.com/spreadsheets/d/1SmpSBXuA-RCJX6VljXCh_Wo9WwbxZ3hX74TFH-1CmC0/edit?usp=sharing',
    featuredImgSrcsets: [{
      src: '/img/pages/swim-pace-calculator/hero-small.webp 1x, /img/pages/swim-pace-calculator/hero-small@2x.webp 2x',
      size: '(max-width: 744px)',
      aspectRatio: '784 / 380'
    }, {
      src: '/img/pages/swim-pace-calculator/hero-medium.webp 1x, /img/pages/swim-pace-calculator/hero-medium@2x.webp 2x',
      size: '(max-width: 1023px)',
      aspectRatio: '1200 / 642'
    }, {
      src: '/img/pages/swim-pace-calculator/hero-large.webp 1x, /img/pages/swim-pace-calculator/hero-large@2x.webp 2x',
      size: '(min-width: 1024px)',
      aspectRatio: '1604 / 674'
    }],
    featuredImgAlt: "Professional swimmer's head emerging from the water, surrounded  by mathmatical symbols",
    features: [{
      text: 'Free'
    }, {
      text: 'No Signup'
    }, {
      text: 'Save & Share',
      tooltip: 'Your answers are auto-saved privately and securely to your local device - nowhere else. Click the link button to get a sharable url you can send.'
    }]
  },
  images: {
    resourceUrl: '/img/pages/swim-pace-calculator/featured-image.webp',
    resourceUrlAlt: 'Professional swimmer rising out of the water surrounded by timer, ruler, and mathmatical symbols',
    twitterPost: '/img/pages/swim-pace-calculator/twitter-post.webp',
    openGraph: '/img/pages/swim-pace-calculator/og-image.webp'
  },
  icon: '/icons/calculator-swim.svg',
  reviews: [{
    quote: "The perfect tool for working with my endurance clients. Used to be difficult to plan and share their swimming programs, but with this it couldn't be simpler!",
    authorName: 'Mauricio Castro',
    avatarUrl: '/img/authors/mauricio-castro.jpg',
    companyName: 'NSCA Certified Personal Trainer',
    companyUrl: 'https://www.linkedin.com/in/mauricio-castro-2598b1171/',
    score: 5
  }],
  widgets: {
    primary: '4XntVBGWhK39rIFzic8I',
    primaryStaging: 'rYjZmdcUbNnsua8rHs2R'
  },
  articleSections: [{
    name: 'How Swimmers Calculate Pace',
    id: 'how-swimmers-calculate-pace'
  }, {
    name: 'Men’s Freestyle 100m Swim Paces',
    id: 'mens-freestyle-100m-swim-pace'
  }, {
    name: 'Women’s Freestyle 100m Swim Paces',
    id: 'womens-freestyle-100m-swim-pace'
  }, {
    name: 'Men’s Freestyle 400m Swim Paces',
    id: 'mens-freestyle-400m-swim-pace'
  }, {
    name: 'Women’s Freestyle 400m Swim Paces',
    id: 'womens-freestyle-400m-swim-pace'
  }, {
    name: 'Men’s Freestyle 500m Swim Paces',
    id: 'mens-freestyle-500m-swim-pace'
  }, {
    name: 'Women’s Freestyle 500m Swim Paces',
    id: 'womens-freestyle-500m-swim-pace'
  }, {
    name: 'Men’s Freestyle 1,000m Swim Paces',
    id: 'mens-freestyle-1000m-swim-pace'
  }, {
    name: 'Women’s Freestyle 1,000m Swim Paces',
    id: 'womens-freestyle-1000m-swim-pace'
  }, {
    name: 'Men’s Freestyle 1,500m Swim Paces',
    id: 'mens-freestyle-1500m-swim-pace'
  }, {
    name: 'Women’s Freestyle 1,500m Swim Paces',
    id: 'womens-freestyle-1500m-swim-pace'
  }, {
    name: 'Men’s Ironman Swim Paces',
    id: 'mens-ironman-swim-pace'
  }, {
    name: 'Women’s Ironman Swim Paces',
    id: 'womens-ironman-swim-pace'
  }, {
    name: 'Conclusion',
    id: 'conclusion'
  }]
};