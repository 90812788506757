import generalConfig from '@/config/general';
export default {
  createdAt: 1729518764,
  updatedAt: 1729518764,
  slug: 'horizontal-bar-graph-maker',
  heading: 'Horizontal Bar Graph Maker',
  title: 'Horizontal Bar Graph Maker - Free, No Signup',
  description: 'Create horizontal bar graphs with ease. Upload your data from Excel, Google Sheets, or Google Drive.  Save and share your image, totally free.',
  shortDescription: 'Horizontal bar chart maker, complete free. Upload Excel & Google Sheets files, or sync from Google Drive. Easily customize and download.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-horizontal-bar.svg',
  images: {
    resourceUrl: '/img/pages/horizontal-bar-graph-maker/featured-image.webp',
    resourceUrlAlt: 'Horizontial bar graph behind a spreadsheet and Excel icons',
    twitterPost: '/img/pages/horizontal-bar-graph-maker/twitter-post.webp',
    openGraph: '/img/pages/horizontal-bar-graph-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-horizontal-bar-graph',
    name: 'What Is a Horizontal Bar Graph?'
  }, {
    id: 'what-are-horizontal-bar-graphs-used-for',
    name: 'What Are Horizontal Bar Graphs Used For?'
  }, {
    id: 'how-to-create-a-horizontal-bar-graph',
    name: 'How to Create a Horizontal Bar Graph'
  }, {
    id: 'how-to-make-a-horizontal-bar-graph-in-excel',
    name: 'How to Make a Horizontal Bar Graph in Excel'
  }, {
    id: 'how-to-make-a-horizontal-bar-graph-in-google-sheets',
    name: 'How to Make a Horizontal Bar Graph in Google Sheets'
  }, {
    id: `${generalConfig.productName.toLowerCase()}-a-free-horizontal-bar-graph-maker`,
    name: `${generalConfig.productName}: A Free Horizontal Bar Graph Maker`
  }]
};