export default {
  createdAt: 1729110629,
  updatedAt: 1729110629,
  slug: 'bar-graph-maker',
  heading: 'Bar Graph Maker',
  title: 'Bar Graph Maker - Free, No Signup',
  description: 'Create customizable bar charts in your browser for free. Import data from Excel or Google Drive. Download in any size or file format. No signup necessary.',
  shortDescription: 'Create bar charts completely free. Connect data from Excel, Google Sheets, or Google Drive. Customize then download below. No signup necessary.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-bar.svg',
  images: {
    resourceUrl: '/img/pages/bar-graph-maker/featured-image.webp',
    resourceUrlAlt: 'Bar graph behind a legend for data and a spreadsheet',
    twitterPost: '/img/pages/bar-graph-maker/twitter-post.webp',
    openGraph: '/img/pages/bar-graph-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-bar-graph',
    name: 'What Is a Bar Graph?'
  }, {
    id: 'types-of-bar-graphs',
    name: 'Types of Bar Graphs'
  }, {
    id: 'what-are-bar-graphs-good-for',
    name: 'What Are Bar Graphs Good For?'
  }, {
    id: 'how-do-i-create-my-own-bar-graph',
    name: 'How Do I Create My Own Bar Graph?'
  }, {
    id: 'what-is-the-best-program-for-making-a-bar-graph',
    name: 'What is the Best Program for Making a Bar Graph?'
  }, {
    id: 'how-to-make-a-bar-graph-in-excel',
    name: 'How to Make a Bar Graph in Excel'
  }, {
    id: 'how-to-make-a-bar-graph-in-google-sheets',
    name: 'How to Make a Bar Graph in Google Sheets'
  }, {
    id: 'excelkits-a-powerful-free-bar-graph-maker',
    name: 'Excelkits: A Powerful Free Bar Graph Maker'
  }]
};