export default {
  createdAt: 1689750000,
  updatedAt: 1696617355,
  slug: 'donut-chart-maker',
  heading: 'Donut Chart Maker',
  title: 'Donut Chart Maker - Free Image Download, No Signup',
  description: 'Free browser based tool to create a doughnut or pie chart. Import your Excel & Google Sheets. Customize everything, then download as PNG, JPG or WEPB.',
  shortDescription: 'Create a free donut chart. Import your data from Excel & Google Sheets. Customize then download below.  No signup necessary.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/donut-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Doughnut chart surrounded by a mathmatical grid and circle',
    twitterPost: '/img/pages/donut-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/donut-chart-maker/og-image.webp'
  },
  icon: '/icons/chart-donut.svg',
  reviews: [],
  articleSections: [{
    name: 'What is a Donut Chart Maker?',
    id: 'what-is-a-donut-chart-maker'
  }, {
    name: 'What is a Donut Chart Used For?',
    id: 'what-is-a-donut-chart-used-for'
  }, {
    name: 'Why Use A Donut Chart?',
    id: 'why-use-a-donut-chart'
  }, {
    name: 'Exploring Doughnut Chart Alternatives: Pie Charts and Other Chart Types',
    id: 'exploring-doughnut-chart'
  }, {
    name: 'What is a Ring Chart?',
    id: 'what-is-a-ring-chart'
  }, {
    name: 'Can You Make a Donut Chart in Google Sheets?',
    id: 'can-you-make-a-donut-chart'
  }, {
    name: 'How do you make a donut graph in Excel?',
    id: 'how-do-you-make-a-donut-graph'
  }, {
    name: 'Conclusion',
    id: 'conclusion'
  }]
};