export default {
  createdAt: 1690217006,
  updatedAt: 1696617365,
  slug: 'pie-chart-maker',
  heading: 'Pie Chart Maker',
  title: 'Pie Chart Maker - Free, No Signup',
  description: 'Create customizable pie charts in your browser for free. Import data from Excel & Google Sheets. Download in any size or file format. No signup necessary.',
  shortDescription: 'Create a free pie chart. Import data from Excel & Google Sheets. Customize then download below. No signup necessary.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/pie-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Pie chart next to a legend of the category titles',
    twitterPost: '/img/pages/pie-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/pie-chart-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    name: 'What is a Pie Chart?',
    id: 'what-is-a-pie-chart'
  }, {
    name: 'Pie Chart Maker Features',
    id: 'pie-chart-maker-features'
  }, {
    name: 'Can I share my chart online or print it?',
    id: 'can-i-share-my-chart-online-or-print-it'
  }, {
    name: 'What are some different types of pie charts?',
    id: 'what-are-some-different-types-of-pie-charts'
  }, {
    name: 'Build a perfect visual according to your data',
    id: 'build-a-perfect-visual-according-to-your-data'
  }, {
    name: 'Adjust the colors to get the look you want',
    id: 'adjust-the-colors-to-get-the-look-you-want'
  }, {
    name: 'Embed an interactive pie chart',
    id: 'embed-an-interactive-pie-chart'
  }, {
    name: 'I want to create a pie chart for commercial purposes',
    id: 'i-want-to-create-a-pie-chart-for-commercial-purposes'
  }, {
    name: 'Can I upload my own data from a spreadsheet?',
    id: 'can-i-upload-my-own-data-from-a-spreadsheet'
  }, {
    name: 'How to create a pie chart in Google Sheets',
    id: 'how-to-create-a-pie-chart-in-google-sheets'
  }, {
    name: 'How to create pie charts in Excel',
    id: 'how-to-create-pie-charts-in-excel'
  }, {
    name: 'What is the best pie chart maker?',
    id: 'what-is-the-best-pie-chart-maker'
  }, {
    name: 'Why would a pie chart be misleading?',
    id: 'why-would-a-pie-chart-be-misleading'
  }, {
    name: 'What is a disadvantage of using a pie chart?',
    id: 'what-is-a-disadvantage-of-using-a-pie-chart'
  }, {
    name: 'Are donut charts better than pie charts?',
    id: 'are-donut-charts-better-than-pie-charts'
  }, {
    name: 'Are treemaps better than pie charts?',
    id: 'are-treemaps-better-than-pie-charts'
  }, {
    name: 'What type of chart is recommended instead of a pie chart?',
    id: 'what-type-of-chart-is-recommended-instead-of-a-pie-chart'
  }]
};